import i18n from "@/i18n";

import "@daniesy/blocky/style.css";
import "@daniesy/quizzy/style.css";

import { createApp, h } from "vue";
import { createInertiaApp, Head, Link } from "@inertiajs/vue3";

import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

import { installBlocky } from "@daniesy/blocky";
import { installQuizzy } from "@daniesy/quizzy";

import AuthLayout from "@/Shared/Layouts/AuthLayout.vue";
import AdminLayout from "@/Shared/Layouts/AdminLayout.vue";

import { ZiggyVue } from "ziggy-js";

import.meta.glob(["../img/**", "../fonts/**"]);

createInertiaApp({
  resolve: (name) => {
    let page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue"));

    page.then((p) => {
      if (name.startsWith("Auth/")) p.default.layout = AuthLayout;
      else p.default.layout = [AdminLayout];
    });

    return page;
  },
  progress: {
    color: "#29d"
  },
  setup({ el, App, props, plugin }) {
    return createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(installBlocky)
      .use(installQuizzy)
      .component("Link", Link)
      .component("Head", Head)
      .use(i18n)
      .use(ZiggyVue)
      .mount(el);
  }
}).then(() => {});
