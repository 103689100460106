<template>
  <TransitionRoot as="template" :show="drawer.state.active">
    <Dialog as="div" class="relative z-20" @close="drawer.close()">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full scale-75"
              enter-to="translate-x-0 scale-100"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0 scale-100"
              leave-to="translate-x-full scale-75"
            >
              <DialogPanel
                class="pointer-events-auto w-screen transition-all"
                :class="drawer.state.size"
              >
                <div
                  class="relative flex h-full flex-col overflow-y-scroll bg-white dark:bg-zinc-900 shadow-xl"
                >
                  <template v-if="drawer.state.showTitle">
                    <button
                      type="button"
                      class="absolute cursor-pointer right-6 top-6 rounded-md bg-white dark:bg-black text-gray-400 dark:text-zinc-600 hover:text-gray-500 dark:hover:text-zinc-300 focus:ring-2 focus:ring-indigo-500"
                      @click="drawer.close()"
                    >
                      <span class="sr-only">Close panel</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                    <div v-if="drawer.state.title" class="px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between">
                        <h2
                          id="slide-over-heading"
                          class="text-base font-semibold leading-6 text-gray-900 dark:text-zinc-100"
                        >
                          {{ drawer.state.title }}
                        </h2>
                      </div>
                    </div>
                  </template>
                  <!-- Main -->
                  <component
                    :is="drawer.state.component"
                    :data="drawer.state.componentData"
                    @action="handleAction"
                    @close="drawer.close()"
                  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import drawer from "@/Support/Drawer.js";

const handleAction = (data) => {
  drawer.state.callback(data);
  drawer.close();
};
</script>
