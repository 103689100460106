<script setup>
import slideOver from "@/Support/SlideOver.js";
import { XMarkIcon } from "@heroicons/vue/24/outline/index.js";

const handleAction = (data) => {
  slideOver.state.callback(data);
  slideOver.close();
};
</script>

<template>
  <div
    id="slide-over"
    class="transition-transform h-screen fixed top-0 right-0 bottom-0 w-full z-50"
    :class="[slideOver.state.size]"
    v-if="slideOver.state.active"
  >
    <div
      class="relative flex h-full flex-col overflow-y-scroll bg-white/95 dark:bg-zinc-900/95 shadow-xl backdrop-blur-lg"
    >
      <template v-if="slideOver.state.title">
        <button
          type="button"
          class="absolute right-6 top-6 rounded-md bg-white dark:bg-black text-gray-400 dark:text-zinc-600 hover:text-gray-500 dark:hover:text-zinc-300 focus:ring-2 focus:ring-indigo-500"
          @click="slideOver.close()"
        >
          <span class="sr-only">Close panel</span>
          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div v-if="slideOver.state.title" class="px-4 py-6 sm:px-6">
          <div class="flex items-start justify-between">
            <h2
              id="slide-over-heading"
              class="text-base font-semibold leading-6 text-gray-900 dark:text-zinc-100"
            >
              {{ slideOver.state.title }}
            </h2>
          </div>
        </div>
      </template>
      <!-- Main -->
      <component
        :is="slideOver.state.component"
        :data="slideOver.state.componentData"
        @action="handleAction"
        @close="slideOver.close()"
      />
    </div>
  </div>
</template>

<style>
@starting-style {
  #slide-over {
    transform: translateX(100%);
  }
}
@ending-style {
  #slide-over {
    transform: translateX(100%);
  }
}
</style>
