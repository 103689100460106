import { shallowReactive } from "vue";

const state = shallowReactive({
  active: false,
  title: "",
  size: "max-w-2xl",
  component: null,
  componentData: {}
});

let complete;
export default {
  get state() {
    return state;
  },
  show(title, component, componentData = {}) {
    state.title = title;
    state.component = component;
    state.componentData = componentData;
    state.active = true;

    return new Promise((resolve) => {
      complete = resolve;
    });
  },
  reset() {
    state.size = "max-w-2xl";
  },
  close() {
    complete();
    state.active = false;
    setTimeout(() => {
      if (!state.active) {
        state.component = null;
        this.reset();
      }
    }, 1000);
  }
};
