<template>
  <TransitionRoot as="template" :show="modal.state.active">
    <Dialog as="div" class="relative z-10" @close="modal.close()">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 dark:bg-zinc-800 bg-opacity-75 dark:bg-opacity-50 transition-opacity backdrop-blur-xl"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <form
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          @submit.prevent="
            modal.ok(modal.state.component ? modal.state.componentValues : modal.state.userInput)
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              :class="dialogPanelClass"
              class="relative transform overflow-hidden rounded-lg bg-white dark:bg-zinc-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div>
                <LottieAnimation
                  v-if="modal.state.animation"
                  loop
                  class="w-80 mx-auto"
                  :animation-data="modal.state.animation"
                />
                <div
                  v-else-if="modal.isSuccess"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div
                  v-else-if="modal.isWarning"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100"
                >
                  <ExclamationCircleIcon class="h-6 w-6 text-yellow-600" aria-hidden="true" />
                </div>
                <div
                  v-else-if="modal.isDanger"
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                >
                  <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>

                <div class="mt-3 text-center sm:mt-5 space-y-2">
                  <DialogTitle
                    v-if="modal.state.title"
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900 dark:text-zinc-100"
                  >
                    {{ modal.state.title }}
                  </DialogTitle>
                  <p
                    class="text-sm text-gray-500 dark:text-zinc-400"
                    v-html="modal.state.message"
                  />
                </div>
                <template v-if="modal.state.component">
                  <component :is="modal.state.component" v-model="modal.state.componentValues" />
                </template>
                <template v-else>
                  <div v-if="modal.isPrompt" class="my-3 sm:my-5">
                    <input
                      v-model="modal.state.userInput"
                      name="input-prompt"
                      :type="modal.state.inputType"
                      :placeholder="modal.state.inputPlaceholder"
                      :required="modal.state.inputRequired"
                      :min="modal.state.inputType === 'number' ? '1' : null"
                      autofocus
                      maxlength="100"
                      class="block w-full appearance-none rounded-md border border-gray-300 dark:border-zinc-700 px-3 py-2 placeholder-gray-400 dark:placeholder-zinc-600 shadow-xs focus:border-indigo-500 focus:outline-hidden focus:ring-indigo-500 sm:text-sm dark:bg-zinc-800 dark:text-zinc-100"
                    />
                  </div>
                </template>
              </div>
              <div v-if="modal.isAlert" class="mt-5 sm:mt-6">
                <button
                  type="submit"
                  :class="actionButtonClass"
                  class="cursor-pointer inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-xs focus:outline-hidden focus:ring-2 focus:ring-offset-2 sm:text-sm"
                  @click="modal.cancel()"
                >
                  {{ modal.state.okText }}
                </button>
              </div>

              <div
                v-else
                class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
              >
                <button
                  type="submit"
                  :class="actionButtonClass"
                  :disabled="!canSubmit"
                  class="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-bold text-white dark:text-black shadow-xs focus:outline-hidden focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-black sm:col-start-2 sm:text-sm disabled:opacity-30 cursor-pointer"
                >
                  {{ modal.state.okText }}
                </button>
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 dark:border-zinc-700 bg-white dark:bg-zinc-700 px-4 py-2 text-base font-medium text-gray-700 dark:text-zinc-300 shadow-xs hover:bg-gray-50 dark:hover:bg-zinc-800 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm cursor-pointer"
                  @click="modal.cancel()"
                >
                  {{ modal.state.cancelText }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </form>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import {
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon
} from "@heroicons/vue/24/outline";

import modal from "@/Support/Modal.js";
import { LottieAnimation } from "lottie-web-vue";

const dialogPanelClass = computed(() =>
  modal.isAlert ? "sm:w-full sm:max-w-sm" : "sm:w-full sm:max-w-lg"
);
const actionButtonClass = computed(() => {
  if (modal.isWarning) {
    return "bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500";
  }
  if (modal.isDanger) {
    return "bg-red-600 hover:bg-red-700 focus:ring-red-500";
  }
  return "bg-green-600 hover:bg-green-700 focus:ring-green-500";
});
const canSubmit = computed(() =>
  modal.state.component ? modal.state.componentValues.is_valid : true
);
</script>
