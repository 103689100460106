<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed top-20 inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-30"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition-group
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-for="n in Notifications.messages"
          :key="n.id"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-zinc-900 shadow-lg ring-1 ring-black dark:ring-zinc-800 ring-opacity-5 cursor-pointer"
          @click="Notifications.remove(n)"
          @mouseover="Notifications.removeTimeout(n.id)"
          @mouseleave="Notifications.addTimeout(n.id)"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="shrink-0">
                <component
                  :is="icon(n)"
                  class="h-6 w-6"
                  :class="colorClass(n)"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5 space-y-1">
                <p
                  v-if="n.title"
                  class="text-sm font-medium text-gray-900 dark:text-zinc-100"
                  v-html="n.title"
                />
                <p
                  v-if="n.message"
                  class="text-sm text-gray-500 dark:text-zinc-300"
                  v-html="n.message"
                />
              </div>
              <div class="ml-4 flex shrink-0">
                <button
                  type="button"
                  class="inline-flex rounded-md bg-white dark:bg-zinc-800 text-gray-400 dark:text-zinc-600 hover:text-gray-500 dark:hover:text-zinc-300 focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 cursor-pointer"
                  @click.prevent="Notifications.remove(n)"
                >
                  <span class="sr-only">{{ __("Close") }}</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import Notifications from "@/Support/Notifications";

import { CheckCircleIcon, ExclamationCircleIcon, NoSymbolIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";

const colorClass = ({ type }) => {
  switch (type) {
    case "warning":
      return "text-yellow-400";
    case "error":
      return "text-red-400";
    default:
      return "text-green-400";
  }
};

const icon = ({ type }) => {
  switch (type) {
    case "warning":
      return ExclamationCircleIcon;
    case "error":
      return NoSymbolIcon;
    default:
      return CheckCircleIcon;
  }
};
</script>
