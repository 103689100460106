export default class {
  constructor(attributes) {
    Object.assign(this, attributes);
  }

  get canDelete() {
    return !!this.can.delete;
  }

  get canEdit() {
    return !!this.can.edit;
  }

  get canPublish() {
    return true;
    // return !!this.can.publish;
  }

  get canTranslate() {
    return !!this.can.translate;
  }
}
