<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Modal />
  <Popup />
  <Drawer />
  <Notification />
  <SlideOver />

  <div class="flex h-full">
    <Navigation :mobile-menu-opened="mobileMenuOpen" :user="user" @close="mobileMenuOpen = false" />
    <div class="flex flex-1 flex-col overflow-auto">
      <main class="flex-1" :class="{ 'flex flex-col h-full': isFullPage }">
        <header
          id="page-header"
          class="sticky top-0 z-10 bg-gray-50/50 dark:bg-black/50 backdrop-blur-md"
          :class="{ 'shrink-0 grow-0': isFullPage }"
        />

        <slot />
      </main>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";
import emitter from "tiny-emitter/instance";
import Notifications from "@/Support/Notifications.js";
import Pusher from "pusher-js";

import Notification from "@/Shared/Ui/Notifications.vue";
import Navigation from "@/Shared/Ui/Navigation.vue";
import User from "@/Models/User.js";
import Modal from "@/Shared/Ui/Modal.vue";
import Popup from "@/Shared/Ui/Popup.vue";
import Drawer from "@/Shared/Ui/Drawer.vue";
import SlideOver from "@/Shared/Ui/SlideOver.vue";

const mobileMenuOpen = ref(false);

const props = defineProps({
  auth: Object,
  flash: Object,
  isFullPage: Boolean
});

const wait = (timeout) => new Promise((res) => setTimeout(() => res(), timeout));

watchEffect(async () => {
  if (!props.flash.message) {
    return;
  }

  for (let message of props.flash.message) {
    await wait(200);
    Notifications.add(message);
  }
});

const user = computed(() => new User(props.auth?.user));

// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;

const pusher = new Pusher("09cf618174695b04dba1", {
  cluster: "eu",
  forceTLS: true
});

const channel = pusher.subscribe("pusher");
channel.bind("front-notifications", ({ message }) => {
  Notifications.add({ message });
});

channel.bind("email:processed", ({ emailId }) => {
  Notifications.add({ title: "The email processed successfully" });
  emitter.emit("email:processed", emailId);
});

channel.bind("landing-page:processed", ({ landingPageId }) => {
  Notifications.add({ title: "The landing page processed successfully" });
  emitter.emit("landing-page:processed", landingPageId);
});

channel.bind("pill:processed", ({ pillId }) => {
  Notifications.add({ title: "The education pill processed successfully" });
  emitter.emit("pill:processed", pillId);
});
</script>
